import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import UploadIcon from '../icons/Upload';
import CloseIcon from '@material-ui/icons/Close';
import Endpoint from '../endpoint';
import { alertError } from '../helpers';
import Link from '@material-ui/core/Link';
import { Tooltip } from '@material-ui/core';
import RefreshIcon from '../icons/Refresh';

const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginRight: '10px',
      padding: 0,
    },
    closeButton: {
      padding: '5px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#0D8F75',
      textAlign: 'center',
      padding: '10px',
    },
    body: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#0D8F75',
      textAlign: 'center',
      padding: '10px',
    },
    dialogRoot: {
      minWidth: '400px',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '35px',
    },
    loading: {
      marginTop: '15px',
    },
    downloadContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    actionButton: {
      width: '34px',
      height: '34px',
      padding: 0,
      marginLeft: '14px',
      backgroundColor: '#0D8F75',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    downloadIcon: {
      width: '20px',
      height: '20px',
      padding: '6px',
      borderRadius: '50%',
      backgroundColor: '#0D8F75',
      transform: 'rotate(180deg)',
    },
  }),
  { index: 1 }
);

function UploadCSV(props) {
  const classes = useStyles();
  const {
    handleClose,
    open,
    cSVSrc,
    fileType,
    connectionId,
    handleCSVUpdate,
    cSVPrediction,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [isRefreshing, setRefreshing] = useState(false);
  const [data, setData] = useState([]);
  const [getURL, setGetURL] = useState('');
  const preventDefault = (event) => event.preventDefault();

  function dataURItoBlob(dataURI) {
    // console.log(dataURI)
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], { type: mimeString });
    // console.log(byteString)
    // console.log(mimeString)
    // console.log(blob)
    return blob;
  }

  useEffect(() => {
    if (isRefreshing) {
      console.log('data');
      console.log(data);
      setRefreshing(true);
      Endpoint.Users.checkLink(data.response[0].get_url)
        .then((res) => {
          console.log('res upload');
          console.log(res);
          // console.log(res.status)
          if (res.status === 200) {
            setGetURL(data.response[0].get_url);
            // console.log(getURL)
          }

          setRefreshing(false);
        })
        .catch((err) => {
          alertError('Please wait', err);
          setRefreshing(false);
          setLoading(false);
        });
    }
  }, [isRefreshing]);

  const handleRefresh = () => {
    setRefreshing(!isRefreshing);
  };
  useEffect(() => {
    if (open) {
      setGetURL('');
      setLoading(true);
      // console.log("HERE")
      // console.log(cSVSrc)
      var data = new FormData();
      data.append('file', dataURItoBlob(cSVSrc));
      const payload = {
        data: data,
        fileType: fileType,
        connectionId: connectionId,
      };
      Endpoint.Users.upload(payload)
        .then((res) => {
          setData(res.data);
          // console.log(res.data)
          setLoading(false);
          setRefreshing(false);
        })
        .catch((err) => {
          alertError('something went wrong', err);
          setLoading(false);
        });
      // setTimeout(
      //     () => {
      //         setLoading(false);
      //     }, 3000
      // )
    }
  }, [open]);

  // useEffect(() => {

  // }, [uploadCSVDialog==true]);
  // const onClose = () => {
  //     setLoading(true);
  //     handleClose();
  // }

  return (
    <Dialog
      aria-labelledby="Upload CSV"
      open={open}
      classes={{ paper: classes.dialogRoot }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      {!isLoading && (
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.title} variant="h6">
          {isLoading ? (
            'Your file is still uploading'
          ) : (
            <>
              {!cSVPrediction
                ? 'Uploaded! Now processing, please wait'
                : 'File is ready!'}
            </>
          )}
        </Typography>
        {cSVPrediction !== false ? (
          <div className={classes.downloadContent} href={cSVPrediction}>
            <Link className={classes.downloadContent} href={cSVPrediction}>
              <Typography className={classes.body} variant="h6">
                File download link here
              </Typography>
              <UploadIcon className={classes.downloadIcon} />
            </Link>
          </div>
        ) : (
          <>
            <CircularProgress className={classes.loading} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
export default UploadCSV;
