import React from 'react'
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import GlobalStyles from "../helpers/GlobalStyles";

const useStyles = makeStyles((theme) => ({
    loading: {
        width: "100%",
        height: "100%",
        paddingTop: "9vh",
        justifyContent: "center",
        alignItems: "center"
    }
}), {index: 1});

export default function Loading(){
    const globalClasses = GlobalStyles();
    const classes = useStyles();
    return(
        <div className={`${classes.loading} ${globalClasses.flexColumn}`}>
            <CircularProgress color="primary"/>
        </div>
    )
}