import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  InputBase,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import globalStyles from '../helpers/GlobalStyles';
import dialogStyles from './DialogStyles';
import Endpoint from '../endpoint';
import { alertError } from '../helpers';

function ChangePassword(props) {
  const classes = dialogStyles();
  const globalClasses = globalStyles();
  const { handleClose, open, username } = props;

  const handleSubmit = async (values, { setSubmitting }) => {
    // await Endpoint.Users.updatePassword(values)
    //   .then((res) => {
    //     console.log('Change password result');
    //     console.log(res);
    //     Swal.fire({
    //       customClass: {
    //         container: classes.swal,
    //       },
    //       title: 'Success!',
    //       html: 'Password successfully changed',
    //       icon: 'success',
    //     }).then(() => {
    //       handleClose();
    //     });
    //   })
    //   .catch((err) => {
    //     alertError('something went wrong', err);
    //     setSubmitting(false);
    //   });

    await Endpoint.Users.updatePassword(values)
      .then((res) => {
        console.log('resolved...');
        // console.log('Change password result');
        // console.log(res);
        Swal.fire({
          customClass: {
            container: classes.swal,
          },
          title: 'Success!',
          html: 'Password successfully changed',
          icon: 'success',
        }).then(() => {
          handleClose();
        });
      })
      .catch((err) => {
        if (err.response) {
          alertError(`${err.response.data.msg}`, err);
        } else {
          alertError('Something went wrong', err);
        }
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      aria-labelledby="Change Password dialog"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.title} variant="h6">
          Change Password
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          username: username,
          oldPassword: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={object().shape({
          oldPassword: string().required('Required.').min(6).max(20),
          password: string().required('Required.').min(6).max(20),
          confirmPassword: string().required('Required.').min(6).max(20),
        })}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, handleChange, errors }) => (
          <Form className={`${classes.form} ${globalClasses.flexColumn}`}>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="oldPassword">Enter Old Password*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="oldPassword"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.oldPassword}
              />
              {errors.oldPassword && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.oldPassword}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="password">Enter New Password*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="password"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.password}
              />
              {errors.password && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.password}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="confirmPassword">Confirm New Password*</label>
              <InputBase
                className={clsx(classes.dialogInput, globalClasses.input)}
                name="confirmPassword"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.confirmPassword}
              />
              {errors.confirmPassword && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.confirmPassword}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={`${globalClasses.button}`}
              >
                Submit
                {isSubmitting && (
                  <LinearProgress
                    color="primary"
                    className={globalClasses.linearProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default ChangePassword;
