import { createStyles, makeStyles } from '@material-ui/core';

const DialogStyles = makeStyles((theme) =>
    createStyles({
        dialogTitle: {
            marginTop: "10px"
        },
        title: {
            textAlign: "center",
            color: "#147CFF",
            fontWeight: "bold"
        },
        closeButton: {
            position: 'absolute',
            right: "5px",
            top: "15px",
        },
        form: {
            width: "32vh",
            minWidth: "250px",
            padding: "30px",
            paddingTop: 0,
            alignSelf: "flex-start",
            borderRadius: "3vh"
        },
        dialogInput: {
            border: "1px solid #2E414C; !important"
        },
        field: {
            marginTop: "10px",
            '& label': {
                fontSize: "16px",
                color: "#147CFF"
            }
        },
        swal: {
            zIndex: "50000 !important"
        },
        checkBox: {
            color: "#147CFF"
        }
    })
    , {index: 1});


export default DialogStyles;
