import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  LinearProgress,
  InputBase,
  Button,
} from '@material-ui/core';
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form, Formik, useFormikContext, Field } from 'formik';
import { object, string } from 'yup';
import * as Yup from 'yup';
import moment from 'moment';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import UploadIcon from '../icons/Upload';
import CloseIcon from '@material-ui/icons/Close';
import Endpoint from '../endpoint';
import { alertError } from '../helpers';
import Link from '@material-ui/core/Link';
import { Tooltip } from '@material-ui/core';
import RefreshIcon from '../icons/Refresh';
import globalStyles from '../helpers/GlobalStyles';
import stateListObj from '../helpers/allStates.json';
// import PredictionTable from "../components/PredictionTable";

const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      marginRight: '10px',
      padding: 0,
    },
    closeButton: {
      padding: '5px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#0D8F75',
      textAlign: 'center',
      padding: '10px',
    },
    body: {
      fontSize: '20px',
      fontWeight: 500,
      color: '#0D8F75',
      textAlign: 'center',
      padding: '10px',
    },
    dialogRoot: {
      minWidth: '400px',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '35px',
    },
    loading: {
      marginTop: '15px',
    },
    downloadContent: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    actionButton: {
      width: '34px',
      height: '34px',
      padding: 0,
      marginLeft: '14px',
      backgroundColor: '#0D8F75',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    downloadIcon: {
      width: '20px',
      height: '20px',
      padding: '6px',
      borderRadius: '50%',
      backgroundColor: '#0D8F75',
      transform: 'rotate(180deg)',
    },
    filterFields: {
      width: '100%',
      margin: 'auto',
      marginTop: '10px',
      '& label': {
        fontSize: '12px',
        color: 'black',
        marginTop: '10px',
      },
      justifyContent: 'space-evenly',
    },
    filterDivider: {
      margin: 0,
      color: '#808080',
      height: '145px',
      alignSelf: 'center',
    },
    dateInput: {
      color: 'green',
    },
  }),
  { index: 1 }
);

function DashboardFilter(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { clientName, statusUpdate } = props;
  const [isLoading, setLoading] = useState(true);
  const [isRefreshing, setRefreshing] = useState(false);
  const [statusList, setStatusList] = useState([]);
  // const { setFieldValue } = useFormikContext();
  const preventDefault = (event) => event.preventDefault();
  const handleSubmit = (values, { setSubmitting }) => {
    if (values.state == '') {
      values.state = null;
    }
    Endpoint.Users.getData(values)
      .then((res) => {
        console.log(values);
        Swal.fire({
          customClass: {
            container: classes.swal,
          },
          title: 'Success!',
          html: 'Query sent',
          icon: 'success',
        }).then(() => {
          // console.log(res.data)
          // var data = Papa.parse(res.data, {header:true})
          statusUpdate(res.data.response, res.data.filteredList);
          setSubmitting(false);
        });
      })
      .catch((err) => {
        alertError('something went wrong', err);
        setSubmitting(false);
      });
  };
  const currentDate = new Date();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        street: null,
        city: null,
        zipCode: null,
        loanNumber: null,
        startDate: null,
        endDate: null,
        state: '',
      }}
      validationSchema={object().shape({
        state: Yup.string().when(['city'], {
          is: (value) => value && value.length > 0,
          then: Yup.string().required('required.'),
          otherwise: Yup.string().nullable(),
        }),
        city: Yup.string().nullable(),
        zipCode: Yup.number().nullable(),
        loanNumber: Yup.string().nullable(),
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
      }) => (
        <Form className={`${classes.filterFields} ${globalClasses.flexRow}`}>
          <div className={` ${globalClasses.flexColumn}`}>
            <>
              <label htmlFor="city">City*</label>
              <InputBase
                className={clsx(globalClasses.inputFilter)}
                name="city"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.city}
              />
              {errors.city && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.city}
                </div>
              )}
            </>
            <>
              <label htmlFor="state">State*</label>
              <select
                name="state"
                value={values.color}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  display: 'block',
                  background: 'white',
                  border: '1px solid #808080',
                  height: '30px',
                }}
              >
                <option value={null} label="Select a state" />
                {stateListObj.map(({ name, abbreviation }, index) => (
                  <option value={abbreviation} label={name}>
                    {abbreviation}
                  </option>
                ))}
              </select>
              {/* <InputBase
                                        className={clsx( globalClasses.inputFilter)}
                                        name="state"
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        error={!!errors.state}
                                    /> */}
              {errors.state && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.state}
                </div>
              )}
            </>
            <>
              <label htmlFor="zipCode">Zip Code*</label>
              <InputBase
                className={clsx(globalClasses.inputFilter)}
                name="zipCode"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.zipCode}
              />
              {errors.zipCode && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateErrorFilter
                  )}`}
                >
                  Zip Code must be a number
                </div>
              )}
            </>
          </div>
          <hr className={classes.filterDivider} />
          <div className={` ${globalClasses.flexColumn}`}>
            <label htmlFor="loanNumber">loan number*</label>
            <InputBase
              className={clsx(globalClasses.inputFilter)}
              name="loanNumber"
              onChange={handleChange}
              disabled={isSubmitting}
              error={!!errors.loanNumber}
            />
            {errors.loanNumber && (
              <div
                className={`${clsx(
                  globalClasses.validateErrorRed,
                  globalClasses.validateError
                )}`}
              >
                {errors.loanNumber}
              </div>
            )}
          </div>
          <hr className={classes.filterDivider} />
          <div className={` ${globalClasses.flexColumn}`}>
            <label htmlFor="startDate">Start Date*</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormDatePicker />
            </MuiPickersUtilsProvider>
            {/* {errors.startDate && <div className={`${clsx(globalClasses.validateErrorRed, globalClasses.validateError)}`}>{errors.startDate}</div>} */}
            <label htmlFor="endDate">End Date*</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormDatePickerEndDate />
            </MuiPickersUtilsProvider>
            {/* {errors.endDate && <div className={`${clsx(globalClasses.validateErrorRed, globalClasses.validateError)}`}>{errors.endDate}</div>} */}
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className={`${globalClasses.button}`}
            >
              Submit
              {isSubmitting && (
                <LinearProgress
                  color="primary"
                  className={globalClasses.linearProgress}
                />
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const FormDatePicker = (props) => {
  const classes = useStyles();
  return (
    <Field name="startDate">
      {({ field, meta, form: { setFieldValue } }) => {
        return (
          <DatePicker
            {...field}
            helperText={''}
            variant="inline"
            format="yyyy-MM-dd"
            InputProps={
              {
                // disableUnderline: true
              }
            }
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
          />
        );
      }}
    </Field>
  );
};

const FormDatePickerEndDate = (props) => {
  const classes = useStyles();
  return (
    <Field name="endDate">
      {({ field, meta, form: { setFieldValue } }) => {
        return (
          <DatePicker
            {...field}
            helperText={''}
            format="yyyy-MM-dd"
            InputProps={
              {
                // disableUnderline: true
              }
            }
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
          />
        );
      }}
    </Field>
  );
};

export default DashboardFilter;
