import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  BrowserRouter,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from './pages/NotFound';
import { ThemeProvider } from '@material-ui/styles';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import { createTheme } from '@material-ui/core';
import { Fragment } from 'react';

let theme = createTheme({
  palette: {
    primary: {
      main: '#147CFF',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#E47C69',
    },
  },
  typography: {
    fontFamily: 'Arial',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '20px',
      },
    },
  },
});

function App() {
  const [token, setToken] = useState(false);

  const pathname = window.location.pathname;

  useEffect(() => {
    console.log(pathname);
    if (pathname == '/') {
      window.open('http://qualityclaims.com/occutrack', '_self');
    }

    setToken(Cookies.get('_id'));
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/app/dashboard" />}
              // window.open("http://qualityclaims.com/occutrack", "_self")
              // component={() => {
              //   window.location.href = "http://qualityclaims.com/occutrack";
              // }}
            />
            <PrivateRoute
              path="/app"
              component={MainApp}
              isAuthenticated={token}
            />
            <PublicRoute
              exact
              path="/login"
              component={Login}
              isAuthenticated={token}
            />
            <Route component={NotFound} />
          </Switch>
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          // if not authenticated redirect to quality claims website
          // window.open("http://qualityclaims.com/occutrack", "_self")
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

const MainApp = withRouter(({ match }) => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route component={NotFound} exact />
      </Switch>
    </Fragment>
  );
});

export default App;
