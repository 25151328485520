import * as React from "react";

function AdminIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104" {...props}>
      <defs>
        <style>{".prefix__cls-1{fill:#147CFF}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M76.26 67.42a3.68 3.68 0 013.87-2c1.89.28 3.79.58 5.68.91a1 1 0 001.21-.5 42.62 42.62 0 005.9-14.2 1 1 0 00-.51-1.23c-2.17-1.55-4.31-3.16-6.49-4.7a1.6 1.6 0 01-.84-1.7 8.84 8.84 0 000-2.24 1.35 1.35 0 01.69-1.37c2.22-1.59 4.41-3.23 6.64-4.82a1 1 0 00.51-1.23A42.71 42.71 0 0087 20.13a1 1 0 00-1.13-.52c-2.82.47-5.64.9-8.47 1.31a1.36 1.36 0 01-1-.29 26.26 26.26 0 01-2.11-2.11 1.37 1.37 0 01-.33-.95c.4-2.83.84-5.66 1.32-8.47a1 1 0 00-.41-1.1 42.43 42.43 0 00-14.2-5.91 1.06 1.06 0 00-1.25.49c-1.51 2.12-3.09 4.2-4.58 6.34a1.86 1.86 0 01-2 1 7.74 7.74 0 00-2 0 1.38 1.38 0 01-1.39-.67C47.84 7 46.18 4.75 44.57 2.5a1 1 0 00-1.17-.45A42.49 42.49 0 0029.12 8a.91.91 0 00-.46 1.07c.42 2.54.78 5.1 1.24 7.63a2.06 2.06 0 01-.79 2.41c-.55.38-.93 1-1.46 1.43a1.59 1.59 0 01-1.09.37c-2.8-.39-5.59-.87-8.39-1.26a1.28 1.28 0 00-1.06.39 41.2 41.2 0 00-6 14.35 1 1 0 00.5 1.15c2 1.43 3.92 3 6 4.31a2.49 2.49 0 011.38 2.71c-.1.58.06 1.19 0 1.77a1.81 1.81 0 01-.57 1.12c-2.23 1.68-4.5 3.31-6.75 5A3.17 3.17 0 0011 51a2.56 2.56 0 00.05.37A40.62 40.62 0 0017.17 66a1 1 0 00.83.32c1.55-.23 3.11-.44 4.65-.69a4.54 4.54 0 014.45 1.91 4.52 4.52 0 006 1.36 38.67 38.67 0 0138 .11 3.67 3.67 0 005.15-1.58zM51.94 60.8a17.92 17.92 0 1117.91-17.92A17.92 17.92 0 0151.94 60.8z"
            id="prefix__HWPXtZ_tif"
          />
          <path className="prefix__cls-1" d="M86 102a34 34 0 10-68 0" />
          <path fill="none" d="M0 0h104v104H0z" />
        </g>
      </g>
    </svg>
  );
}

export default AdminIcon;
