import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import globalStyles from "../helpers/GlobalStyles";
import {useState} from "react";
import clsx from "clsx";

const useStyles = makeStyles({
    menu: {
        zIndex: 10,
        cursor: "pointer",
        '& .square': {
            width: "10px",
            height: "10px",
            margin: "1.5px",
            background: "#0D8F75",
            WebkitTransition: "all 0.2s ease-in-out",
            MozTransition: "all 0.2s ease-in-out",
            msTransition: "all 0.2s ease-in-out",
            transform: "all 0.2s ease-in-out"
        },
        '&:hover': {
            '& .square': {
                background: "#13af90"
            },
        }
    },
    openMenu: {
        '& .square': {
            margin: "3px"
        }
    },
    closeMenu: {
        '& .square': {
            margin: "1.5px"
        }
    }
}, {index: 1});

function CustomMenu(props) {
    const globalClasses = globalStyles();
    const classes = useStyles();
    const {onClick, onCheck} = props;
    const [isClicked, setClick] = useState(false);
    const [isChecked, setCheck] = useState(false);

    const onMenuClick = () => {
        setCheck(!isChecked);
        onCheck(!isChecked);

        setClick(true);
        onClick(true);
    }
    const menuClass = isClicked ?
        isChecked ? clsx(classes.openMenu, classes.menu) : clsx(classes.closeMenu, classes.menu)
        : classes.menu;

    return (
        <div
            className={`${menuClass} ${globalClasses.flexColumn}`}
            onClick={onMenuClick}
        >
            <div className={globalClasses.flexRow}>
                <span className="square"/>
                <span className="square"/>
                <span className="square"/>
            </div>
            <div className={globalClasses.flexRow}>
                <span className="square"/>
                <span className="square"/>
                <span className="square"/>
            </div>
            <div className={globalClasses.flexRow}>
                <span className="square"/>
                <span className="square"/>
                <span className="square"/>
            </div>
        </div>
    );
}
export default CustomMenu;