import React, {useState} from 'react';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    IconButton,
    Typography, withStyles
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        marginTop: "10px"
    },
    title: {
        textAlign: "center",
        color: "#E47C69",
        fontWeight: "bold"
    },
    closeButton: {
        position: 'absolute',
        right: "5px",
        top: "15px",
    },
    termsP: {
        marginBottom: 0
    },
    termsOl: {
        marginTop: 0
    },
    dialogAction: {
        justifyContent: "center",
        flexDirection: "column"
    },
    checkBox: {
        marginBottom: "10px",
        marginRight: 0
    }
}), {index: 1});

function TermsAndConditions(props) {
    const classes = useStyles();
    const {handleClose, open, acceptTerms} = props;
    const [accept, setAccept] = useState(false);


    return (
        <Dialog
            aria-labelledby="Attestation dialog"
            scroll="paper"
            maxWidth="md"
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose();
                }
            }}
        >
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Typography className={classes.title} variant="h6">Attestation</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    tabIndex={-1}
                >
                    <p >User agrees it will only submit an OccuTrack® query that user has a permissible use for such information and user warrants such permissible use is in accordance with both Gramm-Leach-Bliley Act (GLBA) and Driver’s Privacy Protection Act (DPPA).</p>
                    <p className={classes.termsP}>Permissible use cases under GLBA 15 U.S.C. section 6801, et seq. are as follows:</p>
                    <ol className={classes.termsOl}>
                        <li key={1}>As necessary to effect, administer, or enforce a transaction requested or authorized by the consumer; or</li>
                        <li key={2}>To protect against or prevent actual or potential fraud, unauthorized transactions, claims or other liability; or</li>
                        <li key={3}>For required institutional risk control, or for resolving consumer disputes or inquiries; or</li>
                        <li key={4}>Use by persons holding a legal or beneficial interest relating to the consumer; or</li>
                        <li key={5}>Use by persons acting in a fiduciary or representative capacity on behalf of, and with the implied or express consent of, the consumer; or</li>
                        <li key={6}>To comply with Federal, State or local laws, rules or other applicable legal requirements</li>
                    </ol>
                    <p className={classes.termsP}>Permissible use cases under DPPA 18 U.S.C. section 2721, et seq. are as follows:</p>
                    <ol className={classes.termsOl}>
                        <li key={1}>In connection with any civil, criminal, administrative, or arbitral proceeding, in any federal, state, or local court or agency, or before any self-regulatory body, including the service or process, investigation in anticipation of litigation, and the execution or enforcement of judgments or orders, or pursuant to an order of a federal, state, or local court; or</li>
                        <li key={2}>By any insurer or insurance support organization, or by a self-insured entity, or its agents, employees, or contractors in connection with claims investigation activities or antifraud activities; or</li>
                        <li key={3}>By an employer or its agent or insurer to obtain or verify information relating to a holder of a commercial driver’s license that is required under chapter 313 of title 49, U.S. Code.</li>
                    </ol>
                    <p>User further represents and warrants that the use of the information obtained either directly or indirectly from the OccuTrack® query will not be used in whole or in part, as a factor in establishing an individual’s creditworthiness or eligibility for (i) credit or insurance, or (ii) employment, nor for any other purpose under the Fair Credit Reporting Act.</p>
                </DialogContentText>

            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <FormControlLabel
                    className={classes.checkBox}
                    control={
                        <Checkbox
                            value={accept}
                            color="primary"
                            onChange={e => setAccept(e.target.checked)}
                        />
                    }
                    label="I have read and accept"
                />
                <Button disabled={!accept}
                        variant="contained"
                        onClick={() => {
                            setAccept(false);
                            acceptTerms();
                        }}
                        color="primary"
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const Button = withStyles(theme => ({
    root: props =>
        props.color === "error" && props.variant === "outlined"
            ? {
                borderRadius: 7,
                color: theme.palette.error.dark,
                borderColor: theme.palette.error.main,
                "&:hover": {
                    borderColor: theme.palette.error.dark,
                    // Reset on touch devices, it doesn't add specificity
                    "@media (hover: none)": {
                        borderColor: theme.palette.error.main
                    }
                }
            }
            : {borderRadius: 7}
}))(MuiButton);

export default TermsAndConditions;