import { createStyles, makeStyles } from '@material-ui/core';

const GlobalStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: '100vh',
        alignItems: 'center',
      },
      flexColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      flexRowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      noSelect: {
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        MsUserSelect: 'none',
        userSelect: 'none',
      },
      validateError: {
        color: '#f3c2c2',
        margin: '3px',
        marginLeft: '10px',
        fontSize: '11px',
        fontWeight: 'bolder',
        // width: "100%",
      },
      validateErrorFilter: {
        color: '#f3c2c2',
        margin: '3px',
        marginLeft: '10px',
        fontSize: '11px',
        fontWeight: 'bolder',
        inlineSize: '150px',
        overflowWrap: 'break-word',
        // width: "100%",
      },

      validateErrorRed: {
        color: 'red',
      },
      input: {
        height: '35px',
        width: '100%',
        padding: theme.spacing(2),
        marginTop: '3px',
        borderRadius: '30px',
        fontSize: '14px',
        border: '2px solid #2E414C; !important',
      },
      inputFilter: {
        height: '30px',
        width: '100%',
        padding: '4px',
        marginTop: '4px',
        // marginBottom: "15px",
        fontSize: '12px',
        border: '1px solid #808080; !important',
      },
      button: {
        width: '120px',
        height: '30px',
        fontSize: '16px',
        margin: 'auto',
        marginTop: '12px',
        textTransform: 'none',
      },

      filterButton: {
        width: '80px',
        height: '30px',
        fontSize: '14px',
        marginTop: '12px',
        textTransform: 'none',
        alignSelf: 'end',
      },
      linearProgress: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: 0.4,
        borderRadius: '50px',
      },
    }),
  { index: 1 }
);

export default GlobalStyles;
