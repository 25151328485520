import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import {
  Button,
  IconButton,
  InputBase,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Pagination } from '@material-ui/lab';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import globalStyles from '../helpers/GlobalStyles';
import CustomMenu from '../components/CustomMenu';
import './Dashboard.css';
import AddUser from '../dialogs/AddUser';
import ChangePassword from '../dialogs/ChangePassword';
// import ChangeUsername from '../dialogs/ChangeUsername';
import Endpoint from '../endpoint';
import { alertError } from '../helpers';
import Loading from './Loading';
import UploadIcon from '../icons/Upload';
import AdminIcon from '../icons/Admin.js';
import { ReactComponent as UserIcon } from '../icons/User.svg';
import { ReactComponent as ClientAdminIcon } from '../icons/ClientAdmin.svg';
import { ReactComponent as ClientUserIcon } from '../icons/ClientUser.svg';
import UploadCSV from '../dialogs/UploadCSV';
import DashboardFilterAddress from '../dialogs/DashboardFilterAddress';

import PredictionTable from '../components/PredictionTable';
import PredictionTableDashboard from '../components/PredictionTableDashboard';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import Papa from 'papaparse';
import CsvDownload from 'react-json-to-csv';

const useStyles = makeStyles(
  (theme) => ({
    header: {
      width: '100%',
      position: 'fixed',
      paddingTop: '4vh',
      zIndex: 2,
      background: 'white',
      alignItems: 'center',
    },
    headerContent: {
      width: '100%',
      justifyContent: 'space-between',
    },
    tabContent: {
      display: 'inherit',
      alignItems: 'center',
    },
    tabContentStyle: {
      marginTop: 12,
      marginLeft: 25,
      fontFamily: 'Helvetica Neue',
      '&$selected': {
        backgroundColor: '#147CFF',
      },
    },
    tabContentStyleUploadBatch: {
      // marginTop: 12,
      // marginLeft: 25,
      fontFamily: 'Helvetica Neue',
      '&$selected': {
        backgroundColor: '#147CFF',
      },
    },
    indicator: {
      paddingBottom: '0.75px',
      marginBottom: '12px',
      width: '140px !important',
      marginLeft: '9px',
    },
    indicatorUploadBatch: {
      paddingBottom: '2px',
      marginBottom: '12px',
      width: '90px !important',
      marginLeft: '35px',
    },
    logo: {
      width: '230px',
      height: '40px',
      marginLeft: '12vh',
    },
    menu: {
      marginRight: '3vh',
      alignItems: 'center',
    },
    menuItem: {
      height: 'max-content',
      marginRight: '18px',
      marginLeft: '4px',
      color: '#435465',
      cursor: 'pointer',
      WebkitTransition: 'all 0.2s ease-in-out',
      MozTransition: 'all 0.2s ease-in-out',
      msTransition: 'all 0.2s ease-in-out',
      transform: 'all 0.2s ease-in-out',
      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '15px',
        height: '15px',
        marginRight: '6px',
        marginBottom: '-1px',
        MozBorderRadius: '50%',
        WebkitBorderRadius: '50%',
        borderRadius: '50%',
        backgroundColor: '#147CFF',
      },
    },
    menuItemAdmin: {
      '&.collapsed:nth-child(1)': {
        WebkitTransition: 'translate(+345%, 0)',
        MozTransition: 'translate(+345%, 0)',
        msTransition: 'translate(+345%, 0)',
        transform: 'translate(+345%, 0)',
      },
      '&.collapsed:nth-child(2)': {
        WebkitTransition: 'translate(+175%, 0)',
        MozTransition: 'translate(+175%, 0)',
        msTransition: 'translate(+175%, 0)',
        transform: 'translate(+175%, 0)',
      },
      '&.collapsed:nth-child(3)': {
        WebkitTransition: 'translate(+60%, 0)',
        MozTransition: 'translate(+60%, 0)',
        msTransition: 'translate(+60%, 0)',
        transform: 'translate(+60%, 0)',
      },
    },
    menuItemUser: {
      '&.collapsed:nth-child(1)': {
        WebkitTransition: 'translate(+175%, 0)',
        MozTransition: 'translate(+175%, 0)',
        msTransition: 'translate(+175%, 0)',
        transform: 'translate(+175%, 0)',
      },
      '&.collapsed:nth-child(2)': {
        WebkitTransition: 'translate(+60%, 0)',
        MozTransition: 'translate(+60%, 0)',
        msTransition: 'translate(+60%, 0)',
        transform: 'translate(+60%, 0)',
      },
    },
    exit: {
      marginRight: '18px',
      cursor: 'pointer',
      color: '#435465',
      WebkitTransition: 'all 0.2s ease-in-out',
      MozTransition: 'all 0.2s ease-in-out',
      msTransition: 'all 0.2s ease-in-out',
      transform: 'all 0.2s ease-in-out',
      '& :first-child': {
        marginRight: '5px',
        color: '#147CFF',
      },
      '&.collapsed': {
        WebkitTransition: 'translate(+50%, 0)',
        MozTransition: 'translate(+50%, 0)',
        msTransition: 'translate(+50%, 0)',
        transform: 'translate(+50%, 0)',
      },
    },
    openItemMenu: {
      opacity: 1,
    },
    closeItemMenu: {
      opacity: 0,
      cursor: 'default',
    },
    headerDividerContent: {
      width: '100%',
      marginTop: '2px',
    },
    headerDivider: {
      margin: 0,
      marginLeft: '30px',
      marginRight: '30px',
      borderTop: '1px solid #808080',
    },
    filterDivider: {
      margin: 0,
      color: '#00713E',
      height: '145px',
      alignSelf: 'center',
    },
    contentDivider: {
      margin: 0,
      marginLeft: '3vh',
      marginRight: '3vh',
      marginTop: '30px',
      borderLeft: '1px solid #00713E',
    },
    content: {
      width: '100%',
      paddingTop: 'calc(3vh + 100px)',
      paddingLeft: '4vh',
      marginRight: '50vh',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    uploadBatchcontentTableDashboard: {
      width: '100%',
      paddingTop: 'calc(5vh + 100px)',
      paddingLeft: '5vh',
      paddingRight: '2vh',
      marginRight: '50vh',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    updateBatchContent: {
      width: '100%',
      paddingTop: 'calc(30vh + 100px)',
      paddingLeft: '39%',
      // marginRight: "10vh",
      fontFamily: 'Helvetica Neue',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      alignItems: 'center',
      marginBottom: '10px',
    },
    actions: {
      alignItems: 'center',
      marginBottom: '10px',
    },
    actionsUpdateBatch: {
      // marginTop: "200px",
      // fontFamily: "Helvetica Neue",
    },
    contentTitle: {
      color: '#147CFF',
      marginBottom: '10px',
      fontSize: '25px',
    },
    tableSearchAddress: {
      paddingRight: '1vh',
    },
    uploadBatchcontentTitle: {
      color: '#147CFF',
      marginBottom: '10px',
      marginRight: '25px',
      marginLeft: '27px',
      fontSize: '25px',
    },
    actionButton: {
      width: '300px',
      height: '60px',
      // top: "200px",
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      backgroundColor: '#147CFF',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#147CFF',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionButtonAgain: {
      width: '200px',
      height: '35px',
      float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      backgroundColor: '#147CFF',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#147CFF',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionUsernameButton: {
      width: '200px',
      height: '35px',
      // float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '14%',

      backgroundColor: '#147CFF',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#147CFF',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionPasswordButton: {
      width: '200px',
      height: '35px',
      // float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '14%',
      marginTop: '30px',
      backgroundColor: '#147CFF',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#147CFF',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionUserButton: {
      width: '200px',
      height: '35px',
      // float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '14%',
      marginTop: '30px',
      backgroundColor: '#147CFF',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#147CFF',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    uploadBatchText: {
      textAlign: 'center',
      fontFamily: 'Helvetica Neue',
      color: '#147CFF',
      marginBottom: '50px',
    },
    rightSide: {
      width: '100%',
    },
    form: {
      width: '30vh',
      minWidth: '250px',
      background: '#147CFF',
      padding: '25px',
      alignSelf: 'flex-start',
      borderRadius: '3vh',
    },
    input: {
      height: '30px',
      width: '100%',
      padding: theme.spacing(2),
      marginTop: '3px',
      borderRadius: '30px',
      fontSize: '14px',
      backgroundColor: '#FFFFFF',
    },
    button: {
      width: '120px',
      height: '30px',
      fontSize: '16px',
      margin: 'auto',
      marginTop: '12px',
      textTransform: 'none',
    },
    field: {
      width: '100%',
      margin: 'auto',
      marginTop: '12px',
      '& label': {
        fontSize: '18px',
        color: '#FFFFFF',
      },
    },
    buttonField: {
      marginTop: 0,
    },
    paginator: {
      justifyContent: 'center',
      padding: '10px',
    },
    selectedSearchAddress: {
      color: '#147CFF',
      fontWeight: 800,
    },
    selectedUpdateBatch: {
      color: '#147CFF',
      fontWeight: 800,
    },
    selectedAccount: {
      color: '#147CFF',
      fontWeight: 800,
      // width: "50px !important",
    },
    accountContent: {
      width: '100%',
      paddingTop: 'calc(8vh + 100px)',
      paddingLeft: '40%',
      paddingRight: '32%',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    dialogInput: {
      marginLeft: '32px',
      marginRight: '32px',
      width: '75% !important',
    },
    userIcons: {
      width: '35px',
      marginTop: '8px',
      marginRight: '10px',
    },
    filterFields: {
      width: '100%',
      margin: 'auto',
      marginTop: '12px',
      '& label': {
        fontSize: '12px',
        color: 'black',
      },
      justifyContent: 'space-evenly',
    },
  }),
  { index: 1 }
);

function Dashboard() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [isLoading, setLoading] = useState(true);
  const [userID, setUserID] = useState(false);
  const [username, setUsername] = useState(false);
  const [isClicked, setClick] = useState(false);
  const [isChecked, setCheck] = useState(false);
  const [addUserDialog, setAddUserDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  // const [changeUsernameDialog, setChangeUsernameDialog] = useState(false);
  const [uploadCSVDialog, setUploadCSVDialog] = useState(false);
  const [cSVSrc, setCSVSrc] = React.useState(undefined);
  const [fileType, setFileType] = React.useState(undefined);
  const [isAdmin, setAdmin] = useState(false);
  const [isClientAdmin, setClientAdmin] = useState(false);
  const [clientName, setClientName] = useState(null);
  // const [cSVData, setCSVData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusListDashboard, setStatusListDashboard] = useState([]);
  const [filteredStatusListDashboard, setFilteredStatusListDashboard] =
    useState([]);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [connectionId, setConncectionId] = useState(false);
  const [noOfPages] = React.useState(
    Math.ceil(statusList?.length / itemsPerPage)
  );
  const [cSVPrediction, setCSVPrediction] = useState(false);
  // const [getURL, setGetURL] = useState("")
  const [tabValue, setTabValue] = React.useState(0);
  const [tabValueUploadBatch, setTabValueUploadBatch] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabChangeUploadBatch = (event, newValue) => {
    setTabValueUploadBatch(newValue);
  };

  useEffect(() => {
    // if (localStorage.getItem('connectionId')) {
    //     const wsConnectionId = localStorage.getItem('connectionId')
    //     setConncectionId(wsConnectionId)
    //     console.log(wsConnectionId)
    //     client.onmessage = (message) => {
    //         var msg = JSON.parse(message.data);
    //         console.log(msg)

    //       };
    // }
    // else {
    const client = new W3CWebSocket(
      'wss://8u13kwxw2k.execute-api.us-west-1.amazonaws.com/testing_stage'
    );
    localStorage.setItem('client', client);
    // console.log(client)
    client.onopen = (evt) => {
      console.log('WebSocket Client Connected');
      client.send(JSON.stringify({ action: 'defaultRoute' }));
    };
    client.onmessage = (message) => {
      var msg = JSON.parse(message.data);
      if (msg.hasOwnProperty('connectionId')) {
        var wsConnectionId = msg['connectionId'];
        localStorage.setItem('connectionId', wsConnectionId);
        setConncectionId(wsConnectionId);
      }
      if (msg.url) {
        updateCSVPrediction(msg);
      }
    };
    client.onerror = (evt) => {
      console.log('***onError() :' + evt);
    };

    client.onclose = (evt) => {
      console.log('websocket closed :' + evt.code + ':' + evt.reason);
      console.log(evt);
    };
    // }
  }, []);

  useEffect(() => {
    console.log(cSVPrediction);
    if (cSVPrediction !== false) {
      Endpoint.Users.checkLink(cSVPrediction)
        .then((res) => {
          console.log('CheckLink');
          console.log(res);
          // console.log(res.status)
          if (res.status === 200) {
            // setGetURL(cSVPrediction)
            var data = Papa.parse(res.data, { header: true });
            // console.log(typeof data.data)
            // console.log( data.data)
            // console.log(res.data)
            setStatusList(data.data);
            // console.log(getURL)
          }
          // console.log(typeof res.data)

          // setRefreshing(false)
        })
        .catch((err) => {
          alertError('URL is incorrect', err);
          // setRefreshing(false)
          // setLoading(false);
        });
    }
  }, [cSVPrediction !== false]);

  useEffect(() => {
    Endpoint.Users.get()
      .then((res) => {
        console.log('res from dashboard');
        console.log(res);
        setUserID(res.data.userid);
        setUsername(res.data.username);
        if (res.data.role == 'admin') setAdmin(true);
        else if (res.data.role == 'client_admin') setClientAdmin(true);
        if (res.data.clientName) setClientName(res.data.clientName);
        // setAdmin(res.data.role === "admin");
        console.log(res.data.role);
        console.log(res.data.clientName);

        setLoading(false);
      })
      .catch((err) => {
        console.log('Error!!!!!!!');
        console.log(err);
        alertError('Something went wrong', err);

        setLoading(false);
      });
  }, []);

  const floorFigure = (figure, decimals) => {
    if (!decimals) decimals = 2;
    const d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const openAddUserDialog = () => {
    setAddUserDialog(true);
  };
  const closeAddUserDialog = () => {
    setAddUserDialog(false);
  };
  const openChangePasswordDialog = () => {
    setChangePasswordDialog(true);
  };
  const closeChangePasswordDialog = () => {
    setChangePasswordDialog(false);
  };
  // const openChangeUsernameDialog = () => {
  //   setChangeUsernameDialog(true);
  // };
  // const closeChangeUsernameDialog = () => {
  //   setChangeUsernameDialog(false);
  // };
  const openUploadCSVDialog = () => {
    setUploadCSVDialog(true);
  };
  const closeUploadCSVDialog = () => {
    setUploadCSVDialog(false);
  };

  const updateCSVPrediction = (event) => {
    // console.log("update function: ", event.url)
    setCSVPrediction(event.url);
  };

  const menuAnimationClass = isAdmin
    ? classes.menuItemAdmin
    : classes.menuItemUser;
  const menuClass = isClicked
    ? isChecked
      ? clsx(classes.openItemMenu, classes.menuItem)
      : clsx(classes.closeItemMenu, classes.menuItem) + ' collapsed'
    : clsx(classes.closeItemMenu, classes.menuItem) + 'collapsed';

  const exitClass = true
    ? true
      ? clsx(classes.openItemMenu, classes.exit)
      : clsx(classes.closeItemMenu, classes.exit) + ' collapsed'
    : clsx(classes.closeItemMenu, classes.exit) + ' collapsed';

  const logout = () => {
    Endpoint.Users.logout()
      .then((res) => {
        Cookies.remove('_id');
        window.location = '/login';
      })
      .catch((err) => {
        if (err.response && err.response.status !== 401) {
          Swal.fire(
            'something went wrong',
            err.response ? err.response.data.message : err.message,
            'error'
          );
        }
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    Endpoint.Users.getUserDetails(values)
      .then((res) => {
        setSubmitting(false);
        setStatusList([res.data]);
        console.log(res);
      })
      .catch((err) => {
        console.log('Error!!!!!!!');
        console.log(err);
        alertError('something went wrong', err);
        setSubmitting(false);
      });
  };

  const handleChooseCSV = ({ target }) => {
    const files = target.files;
    // console.log(files[0].name.split('.')[1])
    setFileType(files[0].name.split('.')[1]);
    if (FileReader && files && files.length) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        setCSVSrc(e.target.result);
        openUploadCSVDialog();
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  const handleGetTemplate = () => {
    Endpoint.Users.getTemplate()
      .then((res) => {
        console.log(res.data);
        window.open(res.data.URL, '_blank', 'noopener,noreferrer');
      })
      .catch((err) => {
        console.log('Error!!!!!!!');
        console.log(err);
        alertError('Error!!!!!!!', err);
      });
  };
  const handleStatusListUpdate = (values, filteredValues) => {
    setStatusListDashboard(values);
    setFilteredStatusListDashboard(filteredValues);
  };
  return (
    <div className={`${globalClasses.root}`}>
      <div className={`${globalClasses.flexColumn}`}>
        <AddUser
          handleClose={closeAddUserDialog}
          open={addUserDialog}
          isAdmin={isAdmin}
          isClientAdmin={isClientAdmin}
          clientName={clientName}
        />
        <ChangePassword
          username={username}
          handleClose={closeChangePasswordDialog}
          open={changePasswordDialog}
        />
        {/* <ChangeUsername
          userID={userID}
          setUsername={setUsername}
          handleClose={closeChangeUsernameDialog}
          open={changeUsernameDialog}
        /> */}
        <UploadCSV
          handleClose={closeUploadCSVDialog}
          open={uploadCSVDialog}
          cSVSrc={cSVSrc}
          fileType={fileType}
          connectionId={connectionId}
          handleCSVUpdate={updateCSVPrediction}
          cSVPrediction={cSVPrediction}
        />

        <div className={`${classes.header} ${globalClasses.flexColumn}`}>
          <div className={`${classes.headerContent} ${globalClasses.flexRow}`}>
            <div className={classes.tabContent}>
              <img className={classes.logo} src="/logo.png" alt="logo" />
              <Tabs
                classes={{ indicator: classes.indicator }}
                TabIndicatorProps={{ style: { backgroundColor: '#147CFF' } }}
                value={tabValue}
                indicatorColor="primary"
                onChange={handleTabChange}
                aria-label="simple tabs example"
                className={classes.tabContentStyle}
              >
                <Tab
                  classes={{ selected: classes.selectedSearchAddress }}
                  label="Search Address"
                  {...a11yProps(0)}
                />
                <Tab
                  classes={{ selected: classes.selectedUpdateBatch }}
                  label="Upload Batch"
                  {...a11yProps(1)}
                />
                <Tab
                  classes={{ selected: classes.selectedUpdateBatch }}
                  label="Dashboard"
                  {...a11yProps(1)}
                />
                <Tab
                  classes={{ selected: classes.selectedAccount }}
                  label="Account"
                  {...a11yProps(1)}
                />
              </Tabs>
            </div>

            <div className={`${classes.menu} ${globalClasses.flexRow}`}>
              {/* {isAdmin && <span
                                className={`${menuAnimationClass} ${menuClass}`}
                                onClick={openAddUserDialog}
                            >
                                Add an user
                            </span>}
                            <span
                                className={`${menuAnimationClass} ${menuClass}`}
                                onClick={openChangeUsernameDialog}
                            >
                                Change username
                            </span>
                            <span
                                className={`${menuAnimationClass} ${menuClass}`}
                                onClick={openChangePasswordDialog}
                            >
                                Change password
                            </span> */}
              {isLoading ? (
                <></>
              ) : (
                <>
                  {isClientAdmin && !isAdmin ? (
                    <Tooltip title="Client Admin">
                      <div>
                        <ClientAdminIcon className={`${classes.userIcons}`} />
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      {clientName && !isAdmin ? (
                        <Tooltip title="Client User">
                          <div>
                            <ClientUserIcon
                              className={`${classes.userIcons}`}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <>
                          {isAdmin ? (
                            <Tooltip title="Site Admin">
                              <div>
                                <AdminIcon className={`${classes.userIcons}`} />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Site User">
                              <div>
                                <UserIcon className={`${classes.userIcons}`} />
                              </div>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <span
                className={`${exitClass} ${globalClasses.flexRow}`}
                onClick={logout}
              >
                <ExitToAppIcon />
                Logout
              </span>
              {/* <CustomMenu onClick={setClick} onCheck={setCheck}/> */}
            </div>
          </div>
          <div className={classes.headerDividerContent}>
            <hr className={classes.headerDivider} />
          </div>
        </div>
        <TabPanel value={tabValue} index={0}>
          {!isLoading ? (
            <div className={`${classes.content} ${globalClasses.flexRow}`}>
              <div className={globalClasses.flexColumn}>
                <div className={`${classes.actions} ${globalClasses.flexRow}`}>
                  <span className={classes.contentTitle}>Property Details</span>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    username: username,
                    first_name: '',
                    last_name: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                  }}
                  validationSchema={object().shape({
                    first_name: string().required('Required.'),
                    last_name: string().required('Required.'),
                    street: string().required('Required.'),
                    city: string().required('Required.'),
                    state: string().required('Required.'),
                    zip: string().required('Required.'),
                  })}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                >
                  {({ isSubmitting, handleChange, errors }) => (
                    <Form
                      className={`${classes.form} ${globalClasses.flexColumn}`}
                    >
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="first_name">First name*</label>
                        <InputBase
                          className={classes.input}
                          name="first_name"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.first_name}
                        />
                        {errors.first_name && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.first_name}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="last_name">Last name*</label>
                        <InputBase
                          className={classes.input}
                          name="last_name"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.last_name}
                        />
                        {errors.last_name && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.last_name}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="street">Street name*</label>
                        <InputBase
                          className={classes.input}
                          name="street"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.street}
                        />
                        {errors.street && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.street}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="city">City*</label>
                        <InputBase
                          className={classes.input}
                          name="city"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.city}
                        />
                        {errors.city && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.city}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="state">State*</label>
                        <InputBase
                          className={classes.input}
                          name="state"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.state}
                        />
                        {errors.state && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.state}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <label htmlFor="zip">Zip code*</label>
                        <InputBase
                          className={classes.input}
                          name="zip"
                          onChange={handleChange}
                          disabled={isSubmitting}
                          error={!!errors.zip}
                        />
                        {errors.zip && (
                          <div className={`${globalClasses.validateError}`}>
                            {errors.zip}
                          </div>
                        )}
                      </div>
                      <div
                        className={`${classes.field} ${globalClasses.flexColumn}`}
                      >
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="secondary"
                          disabled={isSubmitting}
                          className={`${globalClasses.button}`}
                        >
                          submit
                          {isSubmitting && (
                            <LinearProgress
                              color="primary"
                              className={globalClasses.linearProgress}
                            />
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <hr className={classes.contentDivider} />
              <div
                className={`${classes.rightSide} ${globalClasses.flexColumn} ${classes.tableSearchAddress}`}
              >
                <span className={classes.contentTitle}>Status</span>
                <PredictionTable statusList={statusList} isAdmin={isAdmin} />
              </div>
            </div>
          ) : (
            <Loading className={`${classes.content}`} />
          )}
        </TabPanel>
      </div>
      <TabPanel value={tabValue} index={1}>
        {!isLoading ? (
          <>
            {cSVPrediction !== false ? (
              <div
                className={`${classes.uploadBatchcontentTableDashboard} ${globalClasses.flexRow}`}
              >
                <div
                  className={`${classes.rightSide} ${globalClasses.flexColumn}`}
                >
                  <span className={`${classes.uploadBatchcontentTitle}`}>
                    <div className={` ${globalClasses.flexRow}`}>
                      <Tabs
                        TabIndicatorProps={{
                          style: { backgroundColor: '#147CFF' },
                        }}
                        value={tabValueUploadBatch}
                        indicatorColor="primary"
                        onChange={handleTabChangeUploadBatch}
                        aria-label="simple tabs example"
                        className={classes.tabContentStyleUploadBatch}
                      >
                        <Tab
                          classes={{ indicator: classes.indicatorUploadBatch }}
                          style={{ fontSize: '20px' }}
                          classes={{ selected: classes.selectedSearchAddress }}
                          label="Table"
                          {...a11yPropsUploadBatch(0)}
                        />
                        <Tab
                          style={{ fontSize: '20px' }}
                          classes={{ selected: classes.selectedUpdateBatch }}
                          label="Dashboard"
                          {...a11yPropsUploadBatch(1)}
                        />
                      </Tabs>
                    </div>
                  </span>

                  <TabPanel value={tabValueUploadBatch} index={0}>
                    <span className={` ${globalClasses.flexRowReverse}`}>
                      <div style={{ alignSelf: 'end', marginTop: '3.5rem' }}>
                        <Tooltip title="Upload CSV Again">
                          <IconButton
                            component="span"
                            className={classes.actionButtonAgain}
                            onClick={() => setCSVPrediction(false)}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: '#FFFF' }}
                            >
                              Upload CSV Again
                            </Typography>
                          </IconButton>
                        </Tooltip>
                        <CsvDownload
                          style={{
                            padding: '5px',
                            borderRadius: '20px',
                            color: 'white',
                            cursor: 'pointer',
                            backgroundColor: '#147CFF',
                          }}
                          data={statusList}
                        />
                      </div>
                    </span>
                    <div style={{ marginTop: '1rem' }}>
                      <PredictionTable
                        statusList={statusList}
                        isAdmin={isAdmin}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel value={tabValueUploadBatch} index={1}>
                    <Box borderColor="#808080" {...dashboardFilterBoxProps}>
                      <DashboardFilterAddress
                        statusUpdate={handleStatusListUpdate}
                        clientName={clientName}
                      />
                    </Box>
                    <span className={` ${globalClasses.flexRowReverse}`}>
                      <div style={{ alignSelf: 'end', marginTop: '3.5rem' }}>
                        <Tooltip title="Upload CSV Again">
                          <IconButton
                            component="span"
                            className={classes.actionButtonAgain}
                            onClick={() => setCSVPrediction(false)}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: '#FFFF' }}
                            >
                              Upload CSV Again
                            </Typography>
                          </IconButton>
                        </Tooltip>
                        <CsvDownload
                          style={{
                            padding: '5px',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            color: 'white',
                            backgroundColor: '#147CFF',
                          }}
                          data={filteredStatusListDashboard}
                        />
                      </div>
                    </span>
                    <div style={{ marginTop: '1rem' }}>
                      <PredictionTableDashboard
                        statusList={statusListDashboard}
                        isAdmin={isAdmin}
                      />
                    </div>
                  </TabPanel>
                </div>
              </div>
            ) : (
              // <div className={`${classes.updateBatchContent} ${globalClasses.flexRow}`}>
              <div className={globalClasses.flexColumn}>
                <div
                  className={`${classes.updateBatchContent} ${classes.actionsUpdateBatch} ${globalClasses.flexRow}`}
                >
                  <div className={globalClasses.flexColumn}>
                    <Box className={classes.uploadBatchText}>
                      <Typography
                        variant="h6"
                        style={{ fontFamily: 'Helvetica Neue' }}
                      >
                        Upload File for batch processing.
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontFamily: 'Helvetica Neue' }}
                      >
                        For more information Follow the{' '}
                        <a href="#" onClick={handleGetTemplate}>
                          Template
                        </a>
                      </Typography>
                    </Box>

                    <input
                      accept="text/csv"
                      onChange={handleChooseCSV}
                      id="button-csv-upload"
                      style={{ display: 'none' }}
                      type="file"
                    />
                    <label htmlFor="button-csv-upload">
                      <Tooltip title="Upload CSV">
                        <IconButton
                          component="span"
                          className={classes.actionButton}
                        >
                          <Typography
                            variant="subtitle"
                            style={{
                              color: '#FFFF',
                              fontFamily: 'Helvetica Neue',
                              fontWeight: '800',
                            }}
                          >
                            Upload Batch
                          </Typography>
                        </IconButton>
                      </Tooltip>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loading className={`${classes.content}`} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {!isLoading ? (
          <>
            <div
              className={`${classes.uploadBatchcontentTableDashboard} ${globalClasses.flexRow}`}
            >
              <div
                className={`${classes.rightSide} ${globalClasses.flexColumn}`}
              >
                <Box borderColor="#808080" {...dashboardFilterBoxProps}>
                  <DashboardFilterAddress
                    statusUpdate={handleStatusListUpdate}
                    clientName={clientName}
                  />
                </Box>
                <div
                  style={{
                    alignSelf: 'end',
                    marginTop: '3.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  {/* <Typography  variant="body1" ><a href={"#"} target="_blank" rel="noopener noreferrer">Click to download file</a></Typography>         */}
                  <CsvDownload
                    style={{
                      padding: '5px',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#147CFF',
                    }}
                    data={filteredStatusListDashboard}
                  />
                </div>
                <div>
                  <PredictionTableDashboard
                    statusList={statusListDashboard}
                    isAdmin={isAdmin}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading className={`${classes.content}`} />
        )}
      </TabPanel>
      <div className={`${globalClasses.flexColumn}`}>
        <TabPanel value={tabValue} index={3}>
          {!isLoading ? (
            <div className={`${classes.accountContent}`}>
              {/* <div className={`${globalClasses.flexRow}`}> */}

              <div className={`${globalClasses.flexColumn}`}>
                <Box borderColor="#808080" {...defaultProps}>
                  <Typography
                    variant="h4"
                    style={{
                      // color: '#147CFF',
                      marginBottom: '20px',
                      textAlign: 'center',
                      fontFamily: 'Helvetica Neue',
                    }}
                  >
                    Account
                  </Typography>
                  <Box bgcolor="#147CFF" {...currentUsernameProps}>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#FFFF',
                        top: '10px',
                        textAlign: 'center',
                        fontFamily: 'Helvetica Neue',
                      }}
                    >
                      Current Username
                    </Typography>
                  </Box>
                  <Box {...currentUsernameProps}>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#147CFF',
                        textAlign: 'center',
                        fontFamily: 'Helvetica Neue',
                      }}
                    >
                      {username}
                    </Typography>
                  </Box>

                  {isClientAdmin && clientName ? (
                    <div style={{}}>
                      <Box bgcolor="#147CFF" {...currentUsernameProps}>
                        <Typography
                          variant="body1"
                          style={{
                            color: '#FFFF',
                            top: '10px',
                            textAlign: 'center',
                            fontFamily: 'Helvetica Neue',
                          }}
                        >
                          Client Name
                        </Typography>
                      </Box>
                      <Box {...currentUsernameProps}>
                        <Typography
                          variant="body1"
                          style={{
                            color: '#147CFF',
                            textAlign: 'center',
                            fontFamily: 'Helvetica Neue',
                          }}
                        >
                          {clientName}
                        </Typography>
                      </Box>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <Tooltip title="Change Username">
                    <IconButton
                      component="span"
                      className={classes.actionUsernameButton}
                      style={{ marginTop: clientName ? '30px' : '80px' }}
                      onClick={openChangeUsernameDialog}
                    >
                      <Typography variant="body1" style={{ color: '#FFFF' }}>
                        Change Username
                      </Typography>
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Change Password">
                    <IconButton
                      component="span"
                      className={classes.actionPasswordButton}
                      onClick={openChangePasswordDialog}
                    >
                      <Typography variant="body1" style={{ color: '#FFFF' }}>
                        Change Password
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  {isAdmin || isClientAdmin ? (
                    <Tooltip title="Change Password">
                      <IconButton
                        component="span"
                        className={classes.actionUserButton}
                        onClick={openAddUserDialog}
                      >
                        <Typography variant="body1" style={{ color: '#FFFF' }}>
                          Add User
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Box>
                {/* </div>  */}
              </div>
            </div>
          ) : (
            <Loading className={`${classes.content}`} />
          )}
        </TabPanel>
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanelUploadBatch(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpaneluploadbatch"
      hidden={value !== index}
      id={`simple-tabpaneluploadbatch-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelUploadBatch.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsUploadBatch(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpaneluploadbatch-${index}`,
  };
}

const defaultProps = {
  bgcolor: 'background.paper',
  color: '#147CFF',
  m: 1,
  border: 2,
  style: {
    width: '17rem',
    height: '27rem',
    paddingTop: '20px',
    borderRadius: '20px',
    display: 'inline-table',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
};
const dashboardFilterBoxProps = {
  bgcolor: 'background.paper',
  color: '#147CFF',
  m: 2,
  border: 2,
  style: {
    width: '40rem',
    height: '14rem',
    paddingTop: '20px',
    borderRadius: '20px',
    display: 'flex',
    margin: 'auto',
  },
};
const currentUsernameProps = {
  m: 1,
  // border: 2,
  style: {
    width: '8.5rem',
    height: '0.2rem',
    alignContents: 'center',
    display: 'inline-table',
    margin: 0,
    marginLeft: '23%',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
};

export default Dashboard;
