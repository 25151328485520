import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Pagination } from '@material-ui/lab';
// import globalStyles from "../helpers/GlobalStyles";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      width: '100%',
      position: 'fixed',
      paddingTop: '4vh',
      zIndex: 2,
      background: 'white',
      alignItems: 'center',
    },
    headerContent: {
      width: '100%',
      justifyContent: 'space-between',
    },
    tabContent: {
      display: 'inherit',
      alignItems: 'center',
    },
    tabContentStyle: {
      marginTop: 12,
      marginLeft: 25,
      fontFamily: 'Helvetica Neue',
      '&$selected': {
        backgroundColor: '#0D8F75',
      },
    },
    indicator: {
      paddingBottom: '2px',
      marginBottom: '12px',
      width: '140px !important',
      marginLeft: '9px',
    },
    logo: {
      width: '230px',
      height: '40px',
      marginLeft: '12vh',
    },
    menu: {
      marginRight: '12vh',
      alignItems: 'center',
    },
    menuItem: {
      height: 'max-content',
      marginRight: '18px',
      marginLeft: '4px',
      color: '#445660',
      cursor: 'pointer',
      WebkitTransition: 'all 0.2s ease-in-out',
      MozTransition: 'all 0.2s ease-in-out',
      msTransition: 'all 0.2s ease-in-out',
      transform: 'all 0.2s ease-in-out',
      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '15px',
        height: '15px',
        marginRight: '6px',
        marginBottom: '-1px',
        MozBorderRadius: '50%',
        WebkitBorderRadius: '50%',
        borderRadius: '50%',
        backgroundColor: '#0D8F75',
      },
    },
    menuItemAdmin: {
      '&.collapsed:nth-child(1)': {
        WebkitTransition: 'translate(+345%, 0)',
        MozTransition: 'translate(+345%, 0)',
        msTransition: 'translate(+345%, 0)',
        transform: 'translate(+345%, 0)',
      },
      '&.collapsed:nth-child(2)': {
        WebkitTransition: 'translate(+175%, 0)',
        MozTransition: 'translate(+175%, 0)',
        msTransition: 'translate(+175%, 0)',
        transform: 'translate(+175%, 0)',
      },
      '&.collapsed:nth-child(3)': {
        WebkitTransition: 'translate(+60%, 0)',
        MozTransition: 'translate(+60%, 0)',
        msTransition: 'translate(+60%, 0)',
        transform: 'translate(+60%, 0)',
      },
    },
    menuItemUser: {
      '&.collapsed:nth-child(1)': {
        WebkitTransition: 'translate(+175%, 0)',
        MozTransition: 'translate(+175%, 0)',
        msTransition: 'translate(+175%, 0)',
        transform: 'translate(+175%, 0)',
      },
      '&.collapsed:nth-child(2)': {
        WebkitTransition: 'translate(+60%, 0)',
        MozTransition: 'translate(+60%, 0)',
        msTransition: 'translate(+60%, 0)',
        transform: 'translate(+60%, 0)',
      },
    },
    exit: {
      marginRight: '18px',
      cursor: 'pointer',
      color: '#445660',
      WebkitTransition: 'all 0.2s ease-in-out',
      MozTransition: 'all 0.2s ease-in-out',
      msTransition: 'all 0.2s ease-in-out',
      transform: 'all 0.2s ease-in-out',
      '& :first-child': {
        marginRight: '5px',
        color: '#0D8F75',
      },
      '&.collapsed': {
        WebkitTransition: 'translate(+50%, 0)',
        MozTransition: 'translate(+50%, 0)',
        msTransition: 'translate(+50%, 0)',
        transform: 'translate(+50%, 0)',
      },
    },
    openItemMenu: {
      opacity: 1,
    },
    closeItemMenu: {
      opacity: 0,
      cursor: 'default',
    },
    headerDividerContent: {
      width: '100%',
      marginTop: '2px',
    },
    headerDivider: {
      margin: 0,
      marginLeft: '30px',
      marginRight: '30px',
      borderTop: '1px solid #808080',
    },
    contentDivider: {
      margin: 0,
      marginLeft: '3vh',
      marginRight: '3vh',
      marginTop: '30px',
      borderLeft: '1px solid #00713E',
    },
    content: {
      width: '100%',
      paddingTop: 'calc(9vh + 100px)',
      paddingLeft: '14vh',
      marginRight: '50vh',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    updateBatchContent: {
      width: '100%',
      paddingTop: 'calc(30vh + 100px)',
      paddingLeft: '30vh',
      marginRight: '14vh',
      fontFamily: 'Helvetica Neue',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    actions: {
      alignItems: 'center',
      marginBottom: '10px',
    },
    actionsUpdateBatch: {
      alignItems: 'center',
      marginBottom: '10px',
      // marginTop: "200px",
      fontFamily: 'Helvetica Neue',
    },
    contentTitle: {
      color: '#0D8F75',
      marginBottom: '10px',
      fontSize: '25px',
    },
    actionButton: {
      width: '300px',
      height: '60px',
      // top: "200px",
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      backgroundColor: '#0D8F75',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionButtonAgain: {
      width: '200px',
      height: '35px',
      float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      backgroundColor: '#0D8F75',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionUsernameButton: {
      width: '200px',
      height: '35px',
      float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      // marginTop: "10px",
      backgroundColor: '#0D8F75',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionPasswordButton: {
      width: '200px',
      height: '35px',
      float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      marginTop: '10px',
      backgroundColor: '#0D8F75',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    actionUserButton: {
      width: '200px',
      height: '35px',
      float: 'right',
      padding: 0,
      color: '#FFFFF',
      marginLeft: '32px',
      marginTop: '10px',
      backgroundColor: '#0D8F75',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: '#0D8F75',
      },
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
      },
    },
    uploadBatchText: {
      textAlign: 'center',
      fontFamily: 'Helvetica Neue',
      color: '#0D8F75',
      marginBottom: '50px',
    },
    rightSide: {
      width: '100%',
    },
    form: {
      width: '30vh',
      minWidth: '250px',
      background: '#0D8F75',
      padding: '25px',
      alignSelf: 'flex-start',
      borderRadius: '3vh',
    },
    input: {
      height: '30px',
      width: '100%',
      padding: theme.spacing(2),
      marginTop: '3px',
      borderRadius: '30px',
      fontSize: '14px',
      backgroundColor: '#FFFFFF',
    },
    button: {
      width: '120px',
      height: '30px',
      fontSize: '16px',
      margin: 'auto',
      marginTop: '12px',
      textTransform: 'none',
    },
    field: {
      width: '100%',
      margin: 'auto',
      marginTop: '12px',
      '& label': {
        fontSize: '18px',
        color: '#FFFFFF',
      },
    },
    buttonField: {
      marginTop: 0,
    },
    paginator: {
      justifyContent: 'center',
      padding: '10px',
    },
    selectedSearchAddress: {
      color: '#0D8F75',
      fontWeight: 800,
    },
    selectedUpdateBatch: {
      color: '#0D8F75',
      fontWeight: 800,
    },
    selectedAccount: {
      color: '#0D8F75',
      fontWeight: 800,
      // width: "50px !important",
    },
    accountContent: {
      width: '100%',
      paddingTop: 'calc(30vh + 100px)',
      paddingLeft: '10vh',
      marginRight: '16vh',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    dialogInput: {
      width: '40%',
    },
  }),
  { index: 1 }
);

function Dashboard(props) {
  const classes = useStyles();
  const { statusList, isAdmin } = props;
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  // const [connectionId, setConncectionId]= useState(false);
  const [noOfPages] = React.useState(
    Math.ceil(statusList?.length / itemsPerPage)
  );

  const floorFigure = (figure, decimals) => {
    if (!decimals) decimals = 2;
    const d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const floorFigure = (figure, decimals) => {
      if (!decimals) decimals = 2;
      const d = Math.pow(10, decimals);
      return (parseInt(figure * d) / d).toFixed(decimals);
    };

    const handleChange = (event, value) => {
      setPage(value);
    };
  }, [statusList]);
  // console.log(statusList)

  return (
    <>
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '20px' }}>#</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Current Street</th>
            <th>Current City</th>
            <th>Current State</th>
            <th>Current Zip Code</th>
            <th>Occupancy Status</th>
            {isAdmin && <th>Score</th>}
          </tr>
        </thead>
        <tbody>
          {statusList
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((status, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{status?.first_name}</td>
                <td>{status?.last_name}</td>
                <td>{status?.street}</td>
                <td>{status?.city}</td>
                <td>{status?.state}</td>
                <td>{status?.zipcode}</td>
                <td
                  style={{
                    background:
                      status?.prediction > 0 && status?.prediction < 0.47
                        ? '#0D8F75'
                        : status?.prediction >= 0.47 &&
                          status?.prediction <= 0.53
                        ? '#E6CC00'
                        : status?.prediction > 0.53 && status?.prediction < 1
                        ? '#D50000'
                        : '',
                    color:
                      (status?.prediction > 0 && status?.prediction <= 0.47) ||
                      (status?.prediction > 0.53 && status?.prediction) < 1
                        ? '#FFF'
                        : '#333',
                    borderBottomRightRadius:
                      !isAdmin && statusList.length === index + 1 ? '10px' : 0,
                  }}
                >
                  {status?.prediction > 0 && status?.prediction < 0.47
                    ? 'Occupied'
                    : status?.prediction >= 0.47 && status?.prediction <= 0.53
                    ? 'Unknown'
                    : status?.prediction > 0.53 && status?.prediction < 1
                    ? 'Vacant'
                    : ''}
                </td>
                {isAdmin && <td>{floorFigure(status?.prediction, 3)}</td>}
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        color="primary"
        showFirstButton
        showLastButton
        classes={{ ul: classes.paginator }}
      />
    </>
  );
}
export default Dashboard;
