import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, InputBase, LinearProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import Cookies from 'js-cookie';
import Endpoint from '../endpoint';
import globalStyles from '../helpers/GlobalStyles';
import { alertError } from '../helpers';
import clsx from 'clsx';
import TermsAndConditions from '../dialogs/TermsAndConditions';

const useStyles = makeStyles(
  () => ({
    loginPage: {
      background: '#147CFF',
      justifyContent: 'center',
    },
    form: {
      maxWidth: '350px',
      minWidth: '350px',
      maxHeight: '350px',
      minHeight: '350px',
      background: 'white',
      padding: '20px',
      margin: '10px',
      borderRadius: '4vh',
      boxShadow: '3px 3px 5px',
    },
    logo: {
      marginTop: '25px',
      marginBottom: '25px',
      // width: '100%',
      height: '70px',
    },
    field: {
      width: '80%',
      margin: 'auto',
      marginTop: '20px',
      '& label': {
        fontSize: '18px',
        color: '#147CFF',
      },
    },
  }),
  { index: 1 }
);

function Login() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [isLoading, setLoading] = useState(false);
  const [termsDialog, setTermsDialog] = useState(false);
  const [id, setId] = useState(undefined);

  const openTermsDialog = () => {
    setTermsDialog(true);
  };
  const closeTermsDialog = () => {
    setTermsDialog(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    await Endpoint.Users.login(values)
      .then((res) => {
        console.log(res);
        if (res.data) {
          localStorage.setItem('accessToken', res.data.tokens.access);
          localStorage.setItem('idToken', res.data.tokens.idToken);
          localStorage.setItem('refreshToken', res.data.tokens.refreshToken);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          setId(res.data.user.id);
          // setId(res.data.user.id);
          setSubmitting(false);
          if (res.data.user.role === 'user') {
            openTermsDialog();
          } else {
            Cookies.set('_id', id);
            window.location = '/app/dashboard';
          }
        }
      })
      .catch((err) => {
        console.log('Login rejected');
        console.log(err);
        if (err.response) {
          alertError(`${err.response.data.msg}`, err);
          // setSubmitting(false);
        } else {
          alertError('Something went wrong', err);
          // setSubmitting(false);
        }
        setSubmitting(false);
      });
  };

  const acceptTerms = () => {
    Cookies.set('_id', id);
    window.location = '/';
    closeTermsDialog();
  };

  return (
    <div
      className={`${classes.loginPage} ${globalClasses.root}  ${globalClasses.flexColumn}`}
    >
      <TermsAndConditions
        isLoading={isLoading}
        acceptTerms={acceptTerms}
        handleClose={closeTermsDialog}
        open={termsDialog}
      />
      <Formik
        enableReinitialize
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={object().shape({
          username: string().required('Required.').min(6).max(20),
          password: string().required('Required.').min(6).max(20),
        })}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, handleChange, errors }) => (
          <Form className={`${classes.form} ${globalClasses.flexColumn}`}>
            <img className={classes.logo} src="/logo.png" alt="logo" />
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="username">username*</label>
              <InputBase
                className={globalClasses.input}
                name="username"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.username}
              />
              {errors.username && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.username}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <label htmlFor="password">password*</label>
              <InputBase
                className={globalClasses.input}
                name="password"
                type="password"
                onChange={handleChange}
                disabled={isSubmitting}
                error={!!errors.password}
              />
              {errors.password && (
                <div
                  className={`${clsx(
                    globalClasses.validateErrorRed,
                    globalClasses.validateError
                  )}`}
                >
                  {errors.password}
                </div>
              )}
            </div>
            <div className={`${classes.field} ${globalClasses.flexColumn}`}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={`${globalClasses.button}`}
              >
                Login
                {isSubmitting && (
                  <LinearProgress
                    color="primary"
                    className={globalClasses.linearProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default Login;
