import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default createSvgIcon(
    <g transform="translate(1 1)">
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M2,9V4.4C1.9,3.2,2.8,2.1,4,2h14c1.2,0.1,2.1,1.1,2,2.3V9"/>
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M15.3,11.8L11,5.9l-4.3,5.9"/>
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M11,5.9V20"/>
    </g>,
    'Upload',
);