import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = "https://hosting.occutrack.com/";
// const baseURL = 'http://localhost:8080/'; // for dev backend

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

const UserBase = '/users';

export function AUTH_ACCESS_TOKEN() {
  return localStorage.getItem('accessToken');
}

export function AUTH_ID_TOKEN() {
  return localStorage.getItem('idToken');
}

export function AUTH_REFRESH_TOKEN() {
  return localStorage.getItem('refreshToken');
}

async function getHeadersAuth() {
  const idToken = await AUTH_ID_TOKEN();
  return {
    'Content-Type': 'application/json',
    Authorization: idToken,
  };
}

async function getHeadersMultiPart() {
  const idToken = await AUTH_ID_TOKEN();
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: idToken,
  };
}

async function getHeaders(type) {
  if (type) {
    return {
      'Content-Type': type,
    };
  } else {
    return {
      'Content-Type': 'application/json',
    };
  }
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('error');
      console.log(error);
      Cookies.remove('_id');
      Cookies.remove('username');
      window.location = '/';
    }
    return Promise.reject(error);
  }
);

const dashboardAxios = axios.create({
  baseURL: 'https://dm6h754pxd.execute-api.us-west-1.amazonaws.com/Prod/',
  withCredentials: false,
});

const Endpoint = {
  Users: {
    // login: (data) =>
    // const headers = await getHeaders();
    //   axios.post(`${UserBase}/login`, data, {
    //     withCredentials: true,
    //     credentials: 'include',
    //   }),
    login: async (data) => {
      const headers = await getHeadersAuth();

      //   send the request to the api and console log the response/error
      return await axios.post(`${UserBase}/login`, data, { headers });
    },
    //     .then((res) => res)
    //     .catch((err) => {
    //       // checkAuth(err?.response?.data?.message);
    //       return err;
    //     });
    // },
    get: async (_) => {
      const headers = await getHeadersAuth();
      const user = localStorage.getItem('user');

      return await axios
        .get(`${UserBase}/getUser`, {
          params: { user },
          headers,
        })
        .then((res) => res)
        .catch((err) => err);
    },
    getByID: async (id) => {
      const headers = await getHeadersAuth();
      axios.get(`${UserBase}/getUser/${id}`, { headers });
    },
    register: async (data) => {
      const headers = await getHeadersAuth();
      const user = localStorage.getItem('user');
      if (user) {
        data.user = user;
      }
      return await axios.post(`${UserBase}/register`, data, { headers });
      // .then((res) => res)
      // .catch((err) => err);
    },
    // updateUsername: (data) => axios.post(`${UserBase}/updateUsername`, data),
    updatePassword: async (data) => {
      const headers = await getHeadersAuth();
      return await axios.post(`${UserBase}/password-reset`, data, {
        headers,
      });
    },
    logout: async (_) => {
      const headers = await getHeadersAuth();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      axios.get(`${UserBase}/logout`, { headers });
      // axios.get(`${UserBase}/logout`);
    },
    getUserDetails: (data) =>
      dashboardAxios.post('realtime-occupancy-prediction', data),
    updateTerms: async (_) => {
      const headers = await getHeadersAuth();
      return await axios.put(`${UserBase}/updateTerms`), { headers };
    },
    upload: async (data) => {
      const headers = await getHeadersAuth();
      return await axios
        .post(
          `${baseURL}upload/${data.connectionId}/${data.fileType}`,
          data.data,
          {
            headers,
          }
        )
        .then((res) => res)
        .catch((err) => err);
    },

    checkLink: (link) => axios.get(link),
    getTemplate: (_) => axios.get(`${baseURL}template`),
    getData: async (data) => {
      console.log('Get data data');
      console.log(data);
      const headers = await getHeadersAuth();
      const user = localStorage.getItem('user');
      if (user) {
        data.user = user;
      }
      return await axios.post(`${baseURL}data`, data, { headers });
    },
  },
};

export default Endpoint;
