import * as React from 'react';
import {createSvgIcon} from "@material-ui/core";

export default createSvgIcon(
    <g transform="translate(0.62 -2.939)">
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M2.4,8.4v4.9h4.9"/>
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M20.4,21.5v-4.9h-4.9"/>
        <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M18.3,12.5C17,8.6,12.8,6.6,8.9,8c-1,0.4-2,1-2.8,1.7l-3.8,3.6 M20.4,16.6l-3.8,3.6
		C13.7,23,9,23,6.2,20.1c-0.8-0.8-1.4-1.7-1.7-2.8"/>
    </g>,
    'Refresh',
);